import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { CustomLink } from "./custom-link";

function Header(props: Props) {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { registrationLink, resultsLink } = props.data.navbarData.frontmatter
  return (
    <header className="text-base bg-primary">
      <div className="flex flex-wrap items-center justify-between max-w-4xl mx-auto p-4 md:p-8">
        <Link className="flex items-center no-underline text-white" to="/">
          <h1 className="font-bold text-xl tracking-tight text-gray-900">
            {site.siteMetadata.title}
          </h1>
        </Link>

        <button
          className="block md:hidden border border-dark flex items-center px-3 py-2 rounded text-dark"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
            } md:block md:flex md:items-center w-full md:w-auto text-white`}
        >
          {[
            {
              linkURL: `/`,
              title: `Závod`,
              linkType: `internal`,
            },
            ...(registrationLink && registrationLink.shown ? [{
              linkURL: registrationLink.linkURL,
              title: registrationLink.label,
              linkType: registrationLink.linkType,
            }] : []),
            ...(resultsLink && resultsLink.shown ? [{
              linkURL: resultsLink.linkURL,
              title: resultsLink.label,
              linkType: resultsLink.linkType,
            }] : []),
            {
              linkURL: `/trasa`,
              title: `Trasa`,
              linkType: `internal`,
            },
            {
              linkURL: `/historie`,
              title: `Historie`,
              linkType: `internal`,
            },
            {
              linkURL: `/pravidla`,
              title: `Pravidla`,
              linkType: `internal`,
            },
            {
              linkURL: `/kontakt`,
              title: `Kontakt`,
              linkType: `internal`,
            },
          ].map(link => (
            <CustomLink
              activeStyle={{ fontWeight: `bold` }} className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-gray-900 hover:underline uppercase"
              key={link.title}
              linkType={link.linkType as `internal` | `external`}
              linkURL={link.linkURL}
            >
              {link.title}
            </CustomLink>
          ))}
        </nav>
      </div>
    </header>
  );
}


interface NavbarLink {
  shown: boolean
  label: string
  linkType: `internal` | `external`
  linkURL: string
}

export interface NavbarData {
  navbarData: {
    frontmatter: {
      registrationLink?: NavbarLink,
      resultsLink?: NavbarLink
    }
  }
}

interface Props {
  data: NavbarData
}

export default Header;
